//Fonts 
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Signika:300,400,500,600,700');
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');

// Our variables
$base-font-family: 'Open Sans', sans-serif;
$base-font-size:   20px;
$base-font-weight: 400;
$small-font-size:  16px;
$base-line-height: 1.6;

$spacing-unit:     32px;

$text-color:       #505050;
$background-color: #fdfdfd;
$brand-color:      rgb(113, 120, 175);
$brand-color-light: lighten($brand-color, 40%); //#E3E5EF

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%); // #e8e8e8
$grey-color-dark:  darken($grey-color, 25%); //#424242
$top-dark:         #292929;
$site-title:       #e4e4e4;

// Width of the content area
$content-width:    800px; 

$on-phone:         425px;
$on-tablet:        768px;
$on-laptop:        1024px; 

// Imports
@import 'ours/base',
        'ours/header',
		'ours/posts',
		'ours/footer',
		'ours/syntax-highlighting',
		'ours/pages';