.site-footer {
	text-align: left;
	background-color: $grey-color-light;
	border-top: 1px solid $grey-color-light;
	padding-top: $spacing-unit;
	
	.footer-signup {
		display: block;
		padding-bottom: 64px;
	}
	
	h3 {
		@media screen and (min-width:320px) {
			text-align: left;
		}
		
		span {
			font-size: 34px;
		}
	}
	
	input.email {
		width: 50%;
		float: left;
		margin-right: 8px;
		
		@media screen and (max-width: 320px) {
			width: 94%;
		}
	}
	
	.dark-bottom {
		background-color: $grey-color-dark;
		height: 45px;
		
		span {
			color: $site-title;
			float: right;
			padding-top: 16px;
		}
	}
}


/**
 * 3-column footer
 */

.footer-col-wrapper {
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@media screen and (max-width:$on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@media screen and (max-width:$on-tablet) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}
