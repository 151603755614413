/**
 * Layout
 */

.post-content, ul.post-list {
	max-width: calc(625px - (32px*2));
}

/**
 * Posts and readability
 */

.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  line-height: 1;
}

.post-content {
  margin-bottom: $spacing-unit;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: $grey-color;
  text-align: end;
}

.post-link {
  display: block;
  font-size: 24px;
	@media screen and (max-width:$on-phone) {
		text-align: left;
	}
}


/*.post p:first-child {
	font-size: 24px;
	line-height: 1.3;

	@media screen and (max-width:$on-phone) {
		font-size: 22px;
	}
}*/

.post-content li {
	margin-bottom: 24px;
	
	&:last-child {
		//margin-bottom: $spacing-unit;
	}
}

.post-content ul.tight li {
	margin-bottom: 0;
}

p.tight {
	margin-bottom: 12px;
}


/**
 * List of posts
 */
 
.post-list li > ul li {
	margin-bottom: 24px;
	margin-left: $spacing-unit;
	list-style-type: disc;
	
	&:last-child {
		margin-bottom: $spacing-unit;
	}
	
	@media screen and (max-width: $on-tablet) {
		margin-left: 0;
	}
}

.post-list {
	h1 {
		font-size: 26px;
	}
	
	h2 {
		font-size: 26px;
	}
	
	h3 {
		font-size: 24px;
	}
	
	h4 {
		font-size: 22px;
	}
}

.post-list > li {
	border-top: 1px solid $grey-color-light;
	
	&:first-child {
		border: none;
	}
}

h2 a.post-link {
	line-height: 1;
	margin-bottom: 0;
	font-size: 28px;
	
	@media screen and (max-width:$on-laptop) {
		font-size: 26px;
	}
}

.post-list-item h2, h3.search-result-title {
	margin-bottom: 0;
}

h1.post-title {
	margin-bottom: $spacing-unit/4;
	text-align: end;
}

.post-list-item h2 {
	margin-top: $spacing-unit;
}


/**
 * Created elements
 */	
p.sidenote {
	padding: $spacing-unit $spacing-unit*2;
	background-color: $grey-color-light;
	border-left: 10px solid $grey-color;
	border-radius: 5px;
	font-style: italic;
	text-align: left;
	
	@media screen and (max-width:$on-phone) {
		padding: $spacing-unit;
	}
}

.poem {
	padding: $spacing-unit $spacing-unit*2;
	background-color: $grey-color-light;
	border-left: 10px solid $grey-color;
	border-radius: 5px;
	text-align: left;
	font-size: 16px;
	
	@media screen and (max-width:$on-phone) {
		padding: $spacing-unit;
	}
}

/**
 * Post footer
 */

/* --- Post easter egg */
.post-easter-egg {
	text-align:center;
}

/* --- Related Posts */
.related-posts {
	padding-bottom: 2em;
	border-bottom: 1px solid $grey-color-light;
	font-size: 0.9em;
	text-align: left;

	h3 {
		font-size: 26px;
		@media screen and (max-width:$on-laptop) {
			font-size: 22px;
			text-align: left;
		}
	}

	p {
		
		margin-bottom:24px;
		line-height:16px;
		
		@media screen and (max-width:$on-phone) {
			margin-bottom: 16px;
			//line-height: 16px;
		}
		
	}

}

.related-post-items {
	@media screen and (max-width:$on-tablet) {
		font-size: 14px;
	}
}

/* --- Post Nav */
.post-nav {
	font-size: 0.875em;
	padding-top: 30px;
	@media screen and (max-width:$on-tablet) {
		font-size: 12px;
	}
}

.post-nav-next {
	float: right;
	text-align: right;
}


/**
 * Pagination
 */
.pagination ul {
	list-style: none;
	display: flex;
	justify-content: center;
	margin-left: 0;
	margin-top: 8px;
	
	li {
		padding: 4px 8px;
		margin: 0 4px;
		border: 1px solid $grey-color-light;
		
		&:hover, &.current {
			background-color: $brand-color-light;
		}

		a:hover {
			text-decoration: none;
		}
	}	
}
