.site-header {
	background-color: $top-dark;
	border-top: 5px solid $grey-color-dark;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 15px 0 rgba(0, 0, 0, 0.19);
}

.site-title {
	color: $site-title; 
	font-size: 26px;
	font-weight: 300;
	line-height: 56px;
	letter-spacing: -1px;
	margin-bottom: 0;
	float: left;

	@media screen and (max-width: $on-tablet) {	
		padding: 0 15px;
		display: block;
		float: none;
		text-align: center;	
	}

	&:visited {
		color: $site-title; 
	}

	&:hover {
		text-decoration: none;
		color: #fff;
	}
}

.menu-button {
	display: none;

	@media screen and (max-width: $on-phone) {
		display: block;
		color: $grey-color-light;
		background-color: $grey-color-dark;
		border: 1px solid $grey-color-dark;
		padding: 10px 0px;
		border: none;
		font-size: 16px;
		width: 100%;
		border-radius: 5px;
		outline: none;
	}

	&.open {
		border: 1px solid $grey-color;
		color: $grey-color;
		background-color: transparent;
		outline: none;
	}
}

.site-nav {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	@media screen and (min-width: $on-phone) and (max-width: $on-tablet) {
		border-top: 1px solid $grey-color;
		justify-content: center;
	}

	@media screen and (max-width: $on-phone) {
		display: none;
		width: 100%;

		&.open {
			display: flex;
		}
	}
}

.nav-menu {
	margin: 0;
	padding: 0;
	list-style: none;

	@media screen and (max-width: $on-phone) {
		overflow: hidden;
		background-color: $grey-color-dark;
		visibility: hidden; // comment this
		display: none; // comment this
		overflow-x: visible;

		&.open {
			visibility: visible; // comment this
			display: block; // comment this
			overflow-y: visible;
			width: 100%;
		}
	}
}

.nav-item {
	float: left;
	position: relative;

	@media screen and (max-width: $on-phone) {	
		width: 100%;
		margin: 0;
	}

	a {
		display: block;
		background: $top-dark;
		color: $site-title;
		text-decoration: none;
		padding: 20px;
		position: relative;
		font-size: 14px;

		@media screen and (max-width: $on-phone) {
			padding: 1em 1.5em;
			border-bottom: 1px solid $grey-color;
		}

		&:visited {
			color: $site-title;
		}

		&:hover {
			color: #fff;
			text-decoration: none;
		}
		
		&:active {
			color: #fff;
		} 
	}

	&.open > a {
		color: #fff;
		background-color: $grey-color-dark;
		
		@media screen and (max-width: $on-phone) {
			background-color: $top-dark;
		}
	}

	&.open > .nav-submenu {
		display: block;
		border-top: 5px solid $text-color;

		@media screen and (max-width: $on-phone) {	
			border: 0px;
		}
		
		li a {
			background-color: $grey-color-dark;
		}

		a {
			padding: 0.5em 1.5em;
			background-color: $grey-color-dark;
			
			@media screen and (max-width: $on-phone) {
				background-color: $top-dark;
			}
		}

		li:first-child a {
			padding-top: 1.5em;
			@media screen and (max-width: $on-phone) {	
				padding-top: 0.5em;
			}
		}

		li:last-child a {
			padding-bottom: 1.5em;
			@media screen and (max-width: $on-phone) {	
				padding-bottom: 0.5em;
			}
		}
		
		&.search-box {
			left:-209px;
			background-color: $grey-color-dark;
			padding: 1em 0.5em;

			@media screen and (max-width: $on-phone) {	
				left: 0px;
				padding: 1em 2em;
				margin: 0;

				form {
					margin-right: 1em;
				}
			}
			
			input.search-box {
				padding: 0.5em;
				background-color: $grey-color-dark;
				border: 1px solid $grey-color;
				border-radius: 5px;
				width: 200px;
				height: 1.5em;
				margin: 0 1em;
				font-size: 14px;
				color: $grey-color-light;
				&:focus {
					outline: none;
					border-color: #fff;
				}
				@media screen and (max-width: $on-phone) {
					width: 100%;
					margin: 0;
				}
			}
		}
	}
}

.nav-submenu {
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	white-space: nowrap;
	z-index: 1;
	display: none;

	@media screen and (max-width: $on-phone) {
		overflow: hidden;
		visibility: hidden;
		display: none;

		&.open {
			visibility: visible; 
			display: block;
			overflow-y: visible;
			position: relative;	
		}

		a {
			line-height: 16px;
		}
	}
}

.caret {
	@media screen and (min-width: $on-phone) {
		display: none;
	}
}
