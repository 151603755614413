// -- Archives page

ul.archive-list {
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	
	@media screen and (max-width:$on-phone) {
		margin-left: 0px;
	}
	
	li {
		list-style-type: none;
		margin-bottom: 16px;
	}
}

// -- Search page

ul#search-results {
	list-style-type: none;
	margin-left: 0;
}

// --- About page

.slides-wrapper {
	
	@media screen and (max-width:$on-phone) {
		padding-left: 0;
	}
	
	.mySlides {
		max-height: 600px;
		
		// @media screen and (max-width:$on-phone) {
		// 	max-height: 300px;
		// }
	}
	
	figcaption {
		text-align: left;
		font-size: 14px;
		line-height: 1;
	}
}


 /* slideshow */
.animate-fading {
	-webkit-animation:fading 10s infinite;
	animation:fading 10s infinite
}

@-webkit-keyframes fading {
	0%{opacity:0}
	50%{opacity:1}
	100%{opacity:0}
}

@keyframes fading {
	0%{opacity:0}
	50%{opacity:1}
	100%{opacity:0}
}